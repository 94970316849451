import "@atman/primitive-extensions";
import * as React from "react";
import {
    CompetencyDevelopmentAssessmentStatus,
    CompetencyDevelopmentAssessmentType,
    IClientGeneralSettings,
    IClientMarketingInfo,
    IPartnerMarketingInfo,
    ITag,
    PerformanceEvaluation,
} from "../models";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IMapEntries } from "mobx";
import { IProductPrice } from "../appLogic/ProductDefinition";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { RoleMatchType } from "../kernel/routing";

// MARK: Enums
export enum Role {
    AdminClient,
    SecurityManager,
    SupportAdmin,
    AdminPartner,
    AdminSupplier,
    AdminClientDelegated,
    BillingManager,
    UserPartner,
    EmployeeManager,
    TeamManager,
    UserClient,
    Developer,
    AuthenticationManager,
    Candidate,
    CoachManager,
}

export enum Browser {
    IE,
    MicrosoftEdge,
    Chrome,
    Firefox,
    Safari,
    Opera,
}

export enum CandidateFilterComparator {
    Is,
    IsNot,
    Contains,
    DoesNotContain,
    IsAfter,
    IsBefore,
    IsOn,
}

export enum CandidateFilterType {
    Text,
    Select,
    Date,
}

export enum CandidateFilterObject {
    Name,
    Email,
    Workspace,
    Tag,
    Team,
    CandidateState,
    Assessments,
    CreationDate,
    EmailSent,
}

export enum CandidateFiltersMatchType {
    All,
    Any,
}

export enum ItemType {
    Candidate,
    User,
    Tag,
    Workspace,
    Team,
    QuickLink,
    Client,
    Partner,
    PerformanceEvaluation,
    JobFit,
    Job,
    Culture,
}

export enum CandidateState {
    NeverTakeTheTest,
    TestStarted,
    TestDone,
    TestProcessing = 999,
}

export enum CheckoutProcessEnum {
    CreditCard,
    PayLater,
    RequestCredits,
}

export const CandidateStateKeys = {
    0: "pending",
    1: "inProgress",
    2: "completed",
};

export enum AssessmentType {
    ProCognitiveTest = 3,
    ProPersonalityTest = 4,
    ProPreferencesTest = 5,
    ProLearningTest = 6,
    ProTripleBottomLineTest = 7,
}

export enum ProductCodeProEnum {
    Integral = 0,
    CognitiveTest = 1,
    PersonalityTest = 2,
    PreferencesTest = 3,
    TripleBottomLineTest = 4,
    LearningModeTest = 5,
    PersonalityStyles = 50,
}

export enum TermConditionComposition {
    Recrutement,
    DevelopmentOrg,
    Normes,
}

export enum ModalTypes {
    CandidateInviteModal,
    TagForm,
    UserForm,
    QuickLinkForm,
    EditTag,
    SendBatchEmail,
    ImportCandidates,
    TeamForm,
    TeamPeopleForm,
    CandidateForm,
    ProductForm,
    JobFitForm,
    ClientForm,
    PartnerForm,
    RequestCredits,
    AddCredits,
    AttributeCredits,
    UpdateCard,
    AddCard,
    NewConsumptionScheduling,
    EditConsumptionScheduling,
    AddExistingUserForm,
    TeamInfoForm,
    WorkspaceForm,
    TransferCandidateToOtherClient,
    CultureFitForm,
    PublishJobFit,
    PublishCultureFit,
    PerformanceEvaluationForm,
    PublishPerformanceEvaluation,
    PreviewPerformanceEvaluation,
    ManagePerformanceEvaluationTemplateMappings,
    SendCandidateReportByEmail,
    DuplicateCandidate,
    TransferCandidateToOtherClientInternal,
    AssignJobToEmployees,
    AssignCultureToTeams,
    ManagerForm,
    AssignEmployeesToManager,
    UpdateUserClientsDelegation,
}

export enum EmailErrorCode {
    EmailNotValid,
    EmailAlreadyInUse,
    ExternalIdNotValid,
    ExternalIdAlreadyInUse,
    SsoNameIdNotValid = 4,
    SsoNameIdAlreadyInUse = 5,
}

export enum CustomJsonTemplateType {
    CustomAssessmentForm,
    JobFit,
    InterviewGuide,
    ExternalAssessmentSection,
}

export enum HttpMethodEnum {
    GET,
    POST,
    PUT,
    DELETE,
    HEAD,
    PATCH,
}

export enum PlatformUseEnum {
    Hire,
    Lead,
    Grow,
}

export enum IndustryEnum {
    Internal,
    Reseller,
    Enterprise,
    HrSpecialist,
}

export enum TransactionType {
    Other,
    Sale,
    Refund,
    ProductUsed,
    Promo,
    ProductReturned,
    // Purchase exists only in the FE. It's to separate Sales from Purchases made on the Partner Level.
    Purchase,
}

export enum AppScope {
    Client = 0,
    Partner = 1,
    Supplier = 2,
    Manager = 3,
    Print = 4,
    Assessment = 5,
    Employee = 6,
}

export enum ProRestrictionType {
    None,
    Restricted,
    Unrestricted,
}

export enum ProductConsumptionMode {
    Blocked,
    Balance,
    Unlimited,
}

export enum CurrencyEnum {
    CAD,
    USD,
    EURO,
}

export enum PurchaseOptionEnum {
    None,
    ContactUs,
    CreditCard,
    Invoice,
}

export enum UserState {
    Active,
    Inactive,
    Deleted,
}

export enum ClientType {
    Default,
    Demo,
    Internal,
    FreeTrial,
}

export enum ReportType {
    Styles,
    Psychometrics,
    TeamDistribution,
    TeamDevelopmentContainer,
    PotentialFit,
    JobFit,
    CultureFit,
    Competencies,
    CompatibilityFit,
    EmployeeDevelopmentContainer,
}

export enum NotificationType {
    ClientBalanceIsLow = 0,
    ClientBalanceIsZero = 1,
    ClientBalanceIsNegative = 2,
    ClientConsumptionForPartner = 3,
    ClientTransactionForPartner = 4,
    ConsumptionSchedulingEnded = 5,
    ConsumptionSchedulingEndSoon = 6,

    BalanceIsLow = 7,
    TermsAndConditionRejected = 8,
    AssessmentCompleted = 9,
    NewProfiles = 10,
    TransactionCompleted = 11,
    NewUserCreated = 12,

    BalanceIsZero = 13,

    OutstandingBalance = 14,
    ClientOustandingBalance = 15,
}

export enum EmailNotificationFrequencyEnum {
    Disabled = 0,
    Enabled = 1,
    Daily = 2,
    Weekly = 3,
    Monthly = 4,
}

export enum DateGraphFrequency {
    Daily,
    Weekly,
    Monthly,
}

export enum SearchAllCategory {
    Candidate,
    Team,
    Client,
    Partner,
}

export enum AppContextSearchScope {
    Client,
    Partner,
}

export enum EmployeeRole {
    CLevel,
    Director,
    Manager,
    Employee,
}

export enum CompetencyProfile {
    Management,
    Sales,
    Professional,
    Technician,
    Generic,
}

export enum EmployeeSupervisedSince {
    ZeroToFiveMonths,
    SixToTwelveMonths,
    ThirteenToEighteenMonths,
    MoreThanNineteenMonths,
}

export enum PerformanceEvaluationStatus {
    Started,
    Completed,
    Cancelled,
    Restarted,
}

export enum DefaultJobFitTemplateEnum {
    Empty,
    Sales,
    Management,
    Generic,
}

export enum JobFitTypeEnum {
    Generic,
    Personalized,
}

export enum PublishableDocumentState {
    Drafted,
    Published,
}

export enum ProcessState {
    ToDo,
    Done,
    ToReDo,
    Errored,
    NotReady,
}

export enum CompetencyGroupEnum {
    Analysis,
    Discipline,
    Energy,
    Influence,
    Relation,
    Interests,
}

export enum PersonalityDimensionGroupEnum {
    Openness,
    Conscientiousness,
    Agreeableness,
    Extraversion,
    EmotionalStability,
}

export interface IPenaltyResult {
    flagIdentifier: string;
    name: string;
    description: string;
    negativeImpact: number;
}

// MARK: Interfaces
export interface IViewBag {
    title: string;
    scriptVersion: string;
    pageLoadedTimestamp: number;
    isInDevEnvironment: boolean;
    enableMiniProfiler: boolean;
    scope: IScope;
    baseInfo: IBaseInfo;
}

export interface IBaseInfo {
    userInfo: IUserInfo;
    highestRole: string;
    subscriptionInfo: ISubscriptionInfo;
    clientBalance?: number;
    clientName: string;
    partnerName: string;
    clientId: string;
    partnerId: string;
    partnerProId: string;
    competencyDevelopmentEnabled: boolean;
    virtualCoachEnabled: boolean;
    quickLinksEnabled: boolean;
    partnerQuickLinksEnabled: boolean;
    partnerCompetencyDevelopmentEnabled: boolean;
    isNotReseller: boolean;
    partnerCanAllowSsoOnSubOrganizations: boolean;
    customTermsAndConditionsEnabled: boolean;
    partnerIsInternal?: boolean;
    ssoNameId?: string;
    candidateProId?: string;
    candidateHasCompDevProcesses?: boolean;
}

export interface ISubscriptionInfo {
    isSubscriptionClient: boolean;
    price: string;
    subscriptionType: string;
    testQuantity: string;
}

export interface IUserInfo {
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    communicationEmail: string;
    languageCode: string;
    roles: string[];
    userId: string;
    partnerId: number;
    supplierId: string;
    restrictToAssignedWorkspaces: boolean;
    restrictToAllowedTags: boolean;
    intercomUserBlacklisted: boolean;
    currency: string;
}

export type RolesLevel = "client" | "partner" | "supplier";

export interface IScope {
    clientId: string;
    partnerId: number;
    partnerProId: string;
    supplierId: string;
    userId: string;
    siteId?: string;
    workspaceId?: string;
    detail?: any;
    username: string;
    highestRole: string;
    languageCode: string;
    rolesForScope: string[];
    rolesByLevel: Dictionary<RolesLevel, string[]>;
    isMultiClientUser: boolean;
    timeZoneId: string;
}

export interface ITax {
    gst: number;
    pst: number;
    hst: number;
    gstRef: string;
    pstRef: string;
    hstRef: string;
}

export interface IDiscountRate {
    min: number;
    max: number;
    value: number;
}

export interface ISalesRep {
    id: string;
    externalId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    active: boolean;
    phone: string;
}

export interface ICompetencyDevelopmentAssessmentDetails {
    teamId: string;
    assessmentId: string;
    assessmentType: CompetencyDevelopmentAssessmentType;
    assessmentStatus: CompetencyDevelopmentAssessmentStatus;
    employeeId: string;
    employeeProfilePictureUri: string;
    employeeFirstName: string;
    employeeLastName: string;
    employeeEmail: string;
    employeeJobName?: string;
    managerId: string;
    managerFirstName?: string;
    managerLastName?: string;
    managerEmail?: string;
}

export interface IAppModel {
    countries: ICountry[];
    requiredLanguages: ISupportedCulture[];
    availableLanguages: ISupportedCulture[];
    availableReportLanguages: ISupportedCulture[];
    availableAssessmentTypes: AssessmentType[];
    availableAssessmentTypesForInvitation: AssessmentType[];
    availableAssessmentLanguages: ISupportedCulture[];
    availableSalesReps: ISalesRep[];
    defaultSelectedAssessmentTypes: AssessmentType[];
    defaultBundleQuantities: number[];
    effectivePartnerDiscountRates: IDiscountRate[];
    effectiveCanadaTaxes: Dictionary<string, ITax>[];
    purchaseOptions: PurchaseOptionEnum[];
    stripeApiKey: string;
    notificationSettings: INotificationSettings;
    competencyDevelopmentAssessmentDetails: ICompetencyDevelopmentAssessmentDetails;
    sentrySampleRate: number;
    sentryTracesSampleRate: number;
    sentryReplaysSessionSampleRate: number;
    sentryReplaysOnErrorSampleRate: number;
    disableSentry?: boolean;
    allowedLanguageCodesForAssessmentApp?: string[];
    delegatedClientIds?: string[];
    displayPerformanceEvaluationManagementDashboard?: boolean;
    personalityStylesAvailable: boolean;
    personalityStylesGroupId: string;
    personalityStylesGroupModels: IMapEntries<string, string[]>;
}

export interface IReactAppSettings {
    viewBag: IViewBag;
    appModel: IAppModel;
    appUrls: {
        [key: string]: string;
    };
    basePath: string;
    getClientAppBaseUrl(id: string): string;
    getPartnerAppBaseUrl(id: string): string;
    getSupplierAppBaseUrl(id: string): string;
    getAssessmentAppBaseUrl(id: string): string;
}

export interface IWindow extends Window {
    reactAppSettings: IReactAppSettings;
}

export interface ISupportedCulture {
    cultureInfo: string;
    display: string;
    shortDisplay: string;
    name: string;
    shortName: string;
}

export interface IApiError {
    code: string;
    message: string;
    resourceIdentifier: string;
    fieldName: string;
    debugInfo: string;
}

export interface ISidebarSection {
    position: "top" | "bottom";
    items: ISidebarSectionItem[];
}

export interface ISidebarSectionItem {
    href: string;
    iconName: IconName;
    iconProps?: Partial<Omit<FontAwesomeIconProps, "icon">>;
    label: string;
    uniqueKey: string;
    requiredRoles?: Role[];
    requiredRolesMatchType?: RoleMatchType;
    className?: string;
}

export interface IQuickLink {
    aggregateRootId: string;
    clientId: string;
    userId: string;
    uniqueCode: string;
    label: string;
    count: number;
    enabled: boolean;
    assessmentTypes: AssessmentType[];
    tags: string[];
}

export interface IUser {
    aggregateRootId: string;
    firstName: string;
    lastName: string;
    email: string;
    communicationEmail: string;
    languageCode: string;
    canViewRestrictedTags: boolean;
    isRestrictedToAssignedWorkspaces: boolean;
    roles: string[];
    workspaces: string[];
    itemType?: ItemType;
}

export interface IShortUser {
    name: string;
    id: string;
}

export interface IAssessmentType {
    assessmentType: AssessmentType;
    display: string;
}

export interface ICandidate {
    candidateProId: string;
    email: string;
    communicationEmail: string;
    communicationLanguageCode: string;
    candidateState: CandidateState;
    firstName: string;
    lastName: string;
    displayName: string;
    creationDate: number;
    assessmentProcessReturnAnError: boolean;
    externalId: string;
    assessmentNotificationSent: boolean;
    assessmentNotificationSentDate?: number;
    assessmentInfos: IAssessmentInfo[];
    requestedAssessmentTypes: AssessmentType[];
    completedAssessmentTypes: AssessmentType[];
    assessmentId: string;
    startDate: number;
    endDate: number;
    assessmentLanguage: string;
    gender: number;
    userRequestingTest: string;
    assessmentCanvasVersion: string;
    reportCanvasVersion: string;
    assessmentLink?: string;
    profilePictureUri?: string;
    workspaces: ITag[];
    tags: ITag[];
    teams: ITag[];
    itemType?: ItemType;
}

export interface ISummaryProductItem {
    label: string;
    quantity: number;
    price: number;
    productCode: ProductCodeProEnum;
    isCustomPackage: boolean;
    discountRate?: number;
}

export interface IAssessmentInfo {
    assessmentType: AssessmentType;
    assessmentName: string;
    startDate?: number;
    endDate?: number;
    reloadCount: number;
    assessmentElapsedTimeInSec: number;
    sent: boolean;
    nbQuestions?: number;
    nbAnsweredQuestions?: number;
}

export interface ICandidateFilter {
    id: string;
    type: CandidateFilterType;
    comparator: CandidateFilterComparator;
    object: CandidateFilterObject;
    value: string;
    temp?: boolean;
}

// export interface IInviteCandidateParams {
//     candidates: IInviteCandidate[];
//     selectedAssessments: AssessmentType[];
//     selectedTerms: TermConditionComposition[];
//     sendEmail: boolean;
//     language: string;
//     selectedWorkspace?: string;
//     customizedEmailIntroductionText?: string;
//     customizedEmailConclusionText?: string;
// }

export interface ICustomUrl {
    url: string;
    name: string;
    contentId: number;
}

export interface IInviteCandidate {
    email: string;
    customUrls: ICustomUrl[];
}

export interface ICreateUserParams {
    firstName: string;
    lastName: string;
    email: string;
    communicationEmail: string;
    languageCode: string;
    roles: string[];
    notifyUser: boolean;
    isRestrictedToAssignedWorkspaces: boolean;
    canViewRestrictedTags: boolean;
    workspaces?: string[];
}

export interface ICreateTeamParams {
    name: string;
    purpose?: string;
    restricted: boolean;
    ownerIds: string[];
    leadIds: string[];
    memberIds?: string[];
    tagIds?: string[];
}

export interface IItemResult {
    itemType: ItemType;
    items: ICandidate[] | IUser[] | ITag[] | ITeam[];
}

export interface ISectionResult {
    itemType: ItemType;
    items: ICandidate[] | IUser[] | ITag[] | ITeam[];
}

export interface IImportedCandidate {
    firstName: string;
    lastName: string;
    email: string;
    externalId: string;
    ssoNameId?: string;
    customUrls?: {
        [key: string]: string;
    };
    tags: string[];
    errors: EmailErrorCode[];
}

export interface IEmailError {
    errorMessage: string;
    email: string;
    errorCode: EmailErrorCode;
}

export interface ITeam {
    aggregateRootId: string;
    name: string;
    purpose: string;
    restricted: boolean;
    ownerIds: string[];
    memberIds?: string[];
    members?: IShortCandidate[];
    leadIds?: string[];
    leads?: IShortCandidate[];
    itemType?: ItemType;
    optionalManagerAssessment?: boolean;
    optionalSelfAssessment?: boolean;
}

export interface IShortCandidate {
    candidateProId: string;
    firstName: string;
    lastName: string;
    email: string;
    candidateState: CandidateState;
    requestedAssessmentTypes: AssessmentType[];
    completedAssessmentTypes: AssessmentType[];
    profilePictureUri?: string;
    jobId?: string;
    jobName?: string;
    assessmentId: string;
}

export interface ICustomJsonTemplate {
    id: number;
    label: string;
    type: CustomJsonTemplateType;
    content: string;
    dateModified: number;
    modifiedBy: string;
}

export interface IProduct {
    productId: string;
    name: string;
    description?: string;
    sku: string;
    syncStatus: IProductSyncStatus;
    synced: boolean;
    externalIds: IProductExternalIds;
    prices: IProductPrice[];
    active: boolean;
    quantity: number;
}

export interface IProductSyncStatus {
    stripe: boolean;
    pipedrive: boolean;
    quickbooks: boolean;
}

export interface IProductExternalIds {
    stripe: string;
    pipedrive: string;
    quickbooks: string;
}

export interface IAuthTokenPayload {
    iss: string;
    sub: string;
    exp: number;
    roles: string[];
    rolesByLevel: Dictionary<RolesLevel, string[]>;
    userId: string;
    highestRole: string;
    languageCode: string;
    restrictToAllowedTags: boolean;
    restrictToAssignedWorkspaces: boolean;
    automaticClientDelegationEnabled?: boolean;
    isNotReseller: boolean;
    isMultiClientUser: boolean;
    clientId?: string;
    clientName?: string;
    partnerId?: string;
    partnerProId: string;
    partnerName?: string;
    supplierId?: string;
    isSsoAuthenticated: boolean;
}

export interface SearchAllItemResultModel {
    id: string;
    label: string;
    itemType: ItemType;
    clientId?: string;
    clientName?: string;
    partnerId?: string;
    partnerName?: string;
    additionalData?: Record<string, any>;
}

export interface SearchAllQueryResultModel {
    items: SearchAllItemResultModel[];
}

export interface IProductBalance {
    usageModelId: string;
    consumptionMode: ProductConsumptionMode;
    ownerId: string;
    readonly productBalance: Dictionary<ProductCodeProEnum, number>;
}

export interface IBillingAddress {
    address: string;
    address2?: string;
    city: string;
    zipCode: string;
    state: string;
    country: string;
    phone?: string;
    extension?: string;
    mobile?: string;
}

export interface IBillingInformation {
    address: IBillingAddress;
    email: string;
    contactName: string;
    legalName?: string;
    language?: string;
}

export interface IContactInformation extends IBillingInformation {
    name: string;
}

export interface IUpdateBillingAddressCommand {
    contactEmail: string;
    contactName: string;
    legalName?: string;
    language?: string;
    address: string;
    address2?: string;
    city: string;
    zipCode: string;
    stateId: string;
    countryId: string;
    phone?: string;
    extension?: string;
}

export interface IConsumptionSettings {
    restrictionType: ProRestrictionType;
}

export interface IDashboardFilters {
    startDate?: string;
    endDate?: string;
    ownerId?: string;
    sortField?: string;
    sortDirection?: "asc" | "desc";
}

export interface ITransactionLogFilters {
    startDate?: string;
    endDate?: string;
    transactionType?: TransactionType;
    transactionTypes?: TransactionType[];
    otherPartyOwnerId?: string;
    excludeOtherParty?: boolean;
}

export interface IProductBalanceRequestModel {
    transactionProductInfos: ITransactionProductInfo[];
    transactionType: TransactionType;
    comments: string;
    totalAmount: number;
}

export interface IProductBalanceCreditCardRequestModel extends IProductBalanceRequestModel {
    cardIdOrToken: string;
    cardLabel?: string;
}

export interface ITransactionProductInfo {
    productSku: string;
    nbUnits: number;
}

export interface ICountry {
    id: string;
    localizableName: Dictionary<string, string>;
    code: string;
    states: IState[];
}

export interface IState {
    id: string;
    localizableName: Dictionary<string, string>;
    code: string;
}

export interface IStripeCustomerCard {
    cardId: string;
    name: string;
    label: string;
    brand: string;
    country: string;
    expMonth: number;
    expYear: number;
    last4Digits: string;
    updatedByUserId?: string;
    lastUpdated?: number;
}

export interface IAssignableRoles<TRole = string> {
    baseRoles: TRole[];
    additionalRoles: TRole[];
    additionalRolesByBaseRole: Record<string, TRole[]>;
}

export interface IDashboardElements {
    productsGraph: Dictionary<ProductCodeProEnum, number>;
    dateGraph: Dictionary<DateGraphFrequency, IDateGraph[]>;
    ownersUsage: Dictionary<string, IOwnersUsageModel>;
}

export interface IOwnersUsageModel {
    readonly total: number;
    integral: number;
    cognitiveTest: number;
    personalityTest: number;
    preferencesTest: number;
    tripleBottomLineTest: number;
}

export interface IDateGraph {
    startDate: string;
    endDate: string;
    count: number;
    readonly total: number;
    integral: number;
    cognitiveTest: number;
    personalityTest: number;
    preferencesTest: number;
    tripleBottomLineTest: number;
}

export interface ICandidateCountByStateResult {
    total: number;
    pending: number;
    inProgress: number;
    completed: number;
}

export interface IUpdateClientAdditionalInformationModel {
    marketingInfo: IClientMarketingInfo;
    generalSettings: IClientGeneralSettings;
    languageCode: string;
}

export interface IUpdatePartnerAdditionalInformationModel {
    salesRepId?: string;
    marketingInfo: IPartnerMarketingInfo;
}

export interface INotificationSettings {
    emailNotifications: INotificationSettingDetail[];
    inAppNotifications: INotificationSettingDetail[];
}

export interface INotificationSettingDetail {
    type: NotificationType;
    availableFrequencies: EmailNotificationFrequencyEnum[];
    requiredRoles: string[];
    emailNotification: boolean;
    inAppNotification: boolean;
}

export interface IUserNotificationSettings {
    emailNotificationSettings: Dictionary<NotificationType, EmailNotificationFrequencyEnum>;
    inAppNotificationSettings: Dictionary<NotificationType, boolean>;
    userId: string;
    partnerId: string;
    clientId?: string;
}

// MARK: Performance Evaluation Models

export interface ILocalizedText {
    fr: string;
    en: string;
}

export interface ILocalizedLikertStatement {
    statements: ILocalizedText;
    score?: number;
    key: string;
}

export interface IGlobalPerformance {
    value?: number;
    scoreStatements: ILocalizedText[];
}

export interface IPerformanceEvaluationTemplateContentModel {
    competencies: ILocalizedLikertStatement[];
}

export interface IPerformanceEvaluationFormModel {
    competencies: ILocalizedLikertStatement[];
    contextualStatements: ILocalizedLikertStatement[];
    taskStatements: ILocalizedLikertStatement[];
    globalStatements: IGlobalPerformance;
}

export interface IPerformanceEvaluationResultModel {
    competencyPerformance: Dictionary<string, number>;
    contextualPerformance: Dictionary<string, number>;
    taskPerformance: Dictionary<string, number>;
    globalPerformance: number;
    comments?: string;
}

export interface ISavePerformanceEvaluationModel {
    data: IPerformanceEvaluationResultModel;
    currentStep?: number;
}

export interface IEvaluatedCandidate extends IShortCandidate {
    evaluationStep: number;
    evaluationInstance?: PerformanceEvaluation;
    evaluationStatus?: PerformanceEvaluationStatus;
    evaluationCompletedOn?: string;
}

export interface IGetEmployeesResult {
    employees: IEvaluatedCandidate[];
    nextEvaluation: IPerformanceEvaluationNextEvaluation;
}

export interface IPerformanceEvaluationNextEvaluation {
    employeeId?: string;
    nextEvaluationAvailableDate?: string;
}

export interface IPerformanceEvaluationIntroductionForm {
    employeeId: string;
    role: EmployeeRole;
    profile: CompetencyProfile;
    jobTitle: string;
    supervisedSince: EmployeeSupervisedSince;
    consent: boolean;
}

export interface ILikertKeyValueObject {
    key: string;
    value: number;
}

// MARK: Cookies
export interface IRecentFiltersCookie {
    filters: ICandidateFilter[];
    matchType: CandidateFiltersMatchType;
    sortField: string;
    sortOrder: "asc" | "desc";
}

export class Cookies {
    public static readonly PREFERRED_VIEW = "_atmanProPlatform_preferredView";
    public static readonly RECENT_FILTERS = "_atmanProPlatform_recentFilters";

    public static readonly LOGIN_APP_PLATFORM = "_atmanLoginApp_platform";
}

export interface IDateTimeRange {
    min: string;
    max: string;
}

export interface IProductConsumptionModeScheduleEntryModel {
    entryId: string;
    effectiveDate: string;
    endDate?: string;
    consumptionMode: ProductConsumptionMode;
    expectedConsumption: number;
}

export interface IAddProductConsumptionModeScheduleEntryModel {
    effectiveDate: string;
    endDate?: string;
    consumptionMode: ProductConsumptionMode;
    expectedConsumption: number;
}

export interface IRemoveProductConsumptionModeScheduleEntryModel {
    entryId: string;
}

export interface IUpdateProductConsumptionModeScheduleEntryModel extends IAddProductConsumptionModeScheduleEntryModel {
    entryId: string;
}

// MARK: New Interfaces
export interface IAddCandidatesParams {
    selectedProducts: ProductCodeProEnum[];
    selectedTerms: TermConditionComposition[];
    selectedWorkspaces: string[];
    selectedNotificationUserIds: string[];
    sendEmail: boolean;
    language: string;
    customizedEmailIntroductionText?: string;
    customizedEmailConclusionText?: string;
}

export interface IInviteCandidateParams extends IAddCandidatesParams {
    candidates: IInviteCandidate[];
    selectedTags: string[];
}

export interface IImportCandidateParams extends IAddCandidatesParams {
    rows: any[];
}

export interface IInviteCandidate {
    email: string;
    firstName?: string;
    lastName?: string;
    customUrls: ICustomUrl[];
}

export interface ITokenSession {
    id: string;
    location: string;
    ip: string;
    userAgent: string;
    createdOn: string;
    state: TokenSessionState;
}

export interface IBaseReactComponentProps {
    className?: string;
    id?: string;
    style?: React.CSSProperties;
}

export enum TokenSessionState {
    Expired,
    Active,
    Current,
}

export interface IAppContext {
    scope: AppScope;
}

export enum AssessmentAuthMethod {
    Mfa,
    Sso,
    // LinkAndSso,
    // MfaAndSso,
}

export type UserContext = "user" | "candidate";
