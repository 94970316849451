import "./index.less";
import * as React from "react";
import { AtRadioButtonBase, DefaultSizes, IAtRadioButtonBaseProps } from "../../../internal";
import { CSSProperties } from "react";
import { FormGroup, FormText } from "reactstrap";

export interface IRadioFormInputProps extends IAtRadioButtonBaseProps {
    fieldName: string;
    onClick?: (event: React.FormEvent<HTMLInputElement>) => void;
    valueText?: string;
    formText?: string;
    className?: string;
    invalid?: boolean;
    style?: CSSProperties;
    labelSize?: DefaultSizes;
    descriptionSize?: DefaultSizes;
    innerPadding?: DefaultSizes;
}

export const RadioFormInput: React.FC<IRadioFormInputProps> = (props) => {
    const {
        fieldName,
        value,
        valueText,
        onChange,
        checked,
        formText,
        labelSize = "md",
        descriptionSize = "sm",
        innerPadding = "md",
        id,
        className,
        ...otherProps
    } = props;

    return (
        <FormGroup
            className={`RadioFormInput ${fieldName}-input label-${labelSize} inner-padding-${innerPadding} ${
                className ? className : ""
            }`}
        >
            <AtRadioButtonBase
                name={fieldName}
                id={id ?? fieldName}
                value={value}
                checked={checked}
                onChange={onChange}
                label={valueText}
                {...otherProps}
            />
            {formText ? <FormText className={`size-${descriptionSize}`}>{formText}</FormText> : null}
        </FormGroup>
    );
};
