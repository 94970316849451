import { BaseApi } from "../base";
import { HttpMethodEnum } from "../types";
import { ICompetencyDevelopmentProcessData, ICompetencyDevelopmentProcessResultsData } from "../models";
import { IMapEntries } from "mobx";
import { ReactAppSettings } from "../libs";

export class CompetencyDevelopmentProcessesApi extends BaseApi {
    public static getTeamProcesses(teamId: string): Promise<IMapEntries<string, ICompetencyDevelopmentProcessData>> {
        const data = {
            teamId,
        };

        return this.sendApiRequestWithResult<typeof data, IMapEntries<string, ICompetencyDevelopmentProcessData>>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getTeamCompetencyDevelopmentProcesses,
            data,
        );
    }

    public static getProcess(processId: string): Promise<ICompetencyDevelopmentProcessData> {
        return this.sendApiRequestWithResult<undefined, ICompetencyDevelopmentProcessData>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getTeamCompetencyDevelopmentProcess,
            undefined,
            processId,
        );
    }

    public static getProcessResultsData(processId: string): Promise<ICompetencyDevelopmentProcessResultsData> {
        return this.sendApiRequestWithResult<undefined, ICompetencyDevelopmentProcessResultsData>(
            HttpMethodEnum.GET,
            ReactAppSettings.appUrls.getCompetencyDevelopmentProcessResultsData,
            undefined,
            processId,
        );
    }

    public static startProcess(
        teamId: string,
        candidateProId: string,
        discretionalCompetencyIds: string[],
        sendEmailNotification: boolean,
    ): Promise<ICompetencyDevelopmentProcessData> {
        const data = {
            teamId,
            candidateProId,
            discretionalCompetencyIds,
            sendEmailNotification,
        };

        return this.sendApiRequestWithResult<typeof data, ICompetencyDevelopmentProcessData>(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.startCompetencyDevelopmentProcess,
            data,
        );
    }

    public static cancelProcess(processId: string, teamId: string, reason?: string): Promise<void> {
        const data = {
            teamId,
            reason,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.cancelCompetencyDevelopmentProcess,
            data,
            processId,
        );
    }

    public static completeCompetencyProcess(processId: string, teamId: string): Promise<void> {
        const data = {
            teamId,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.completeCompetencyDevelopmentProcess,
            data,
            processId,
        );
    }

    public static resetAssessment(processId: string, assessmentId: string, reason?: string): Promise<void> {
        const data = {
            assessmentId,
            reason,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.resetCompetencyDevelopmentAssessment,
            data,
            processId,
        );
    }

    public static sendSelfAssessmentInvitations(processIds: string[]): Promise<void> {
        const data = {
            processIds,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.sendSelfAssessmentInvitations,
            data,
        );
    }

    public static sendSelfAssessmentReminders(processIds: string[]): Promise<void> {
        const data = {
            processIds,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.sendSelfAssessmentReminders,
            data,
        );
    }

    public static sendManagerAssessmentReminders(processIds: string[]): Promise<void> {
        const data = {
            processIds,
        };

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.sendManagerAssessmentReminders,
            data,
        );
    }

    public static sendDelayedInvitationEmail(userId: string): Promise<void> {
        const data = {};

        return this.sendApiRequestWithResult(
            HttpMethodEnum.POST,
            ReactAppSettings.appUrls.sendDelayedInvitationEmail,
            data,
            userId,
        );
    }
}
