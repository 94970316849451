import "./index.less";
import { ApplySort, AtTableColumn, AtTableItemKeyTypes, AtTableSortOrder } from "../../../types";
import { AtCheckboxBase } from "../../../../../../..";
import { AtTableHeaderCell } from "../AtTableHeaderCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IBaseEntity, useUniqueId } from "@atman/core";
import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

export interface IAtTableHeaderProps<T extends IBaseEntity> {
    columns: AtTableColumn<T>[];
    itemKeyTypes: AtTableItemKeyTypes;
    applySort: ApplySort<T>;
    sortKey: string | undefined;
    sortOrder: AtTableSortOrder;
    hasCheckbox: boolean;
    handleAllCheckboxChange: (() => void) | undefined;
    isSelectAllToggled: boolean | undefined;
    listIdsForCheckbox?: string[];
    listOfDisabledIds?: string[];
    className?: string;
    showChevronDownIcon?: boolean;
    hideCheckboxHeader?: boolean;
}

function AtTableHeaderRaw<T extends IBaseEntity>(props: IAtTableHeaderProps<T>) {
    const {
        columns,
        applySort,
        sortKey,
        sortOrder,
        itemKeyTypes,
        hasCheckbox,
        handleAllCheckboxChange,
        isSelectAllToggled = false,
        listOfDisabledIds = [],
        listIdsForCheckbox = [],
        className,
        showChevronDownIcon = false,
        hideCheckboxHeader = false,
        ...otherProps
    } = props;

    const generatedId = useUniqueId();

    return (
        <thead className={cn("AtTableHeader", className)} {...otherProps}>
            <tr>
                {showChevronDownIcon && (
                    <th className="AtTableHeaderCell iconDropdown">
                        <FontAwesomeIcon icon={["far", "chevron-down"]} />
                    </th>
                )}
                {hasCheckbox && (
                    <th className="AtTableHeaderCell checkbox">
                        {!hideCheckboxHeader && (
                            <AtCheckboxBase
                                id={generatedId.uniqueId}
                                checked={isSelectAllToggled}
                                onChange={handleAllCheckboxChange}
                                size="sm"
                            />
                        )}
                    </th>
                )}
                {columns.map((column) => (
                    <AtTableHeaderCell<T>
                        key={`header-${column.key}`}
                        column={column}
                        itemKeyType={itemKeyTypes[`${column.key}`]}
                        applySort={applySort}
                        sortKey={sortKey}
                        sortOrder={sortOrder}
                    />
                ))}
            </tr>
        </thead>
    );
}

export const AtTableHeader = observer(AtTableHeaderRaw);
