import "./index.less";
import * as React from "react";
import { AtCollapsibleCard, AtIcon, AtInput } from "@atman/design-system";
import { CustomTextInput } from "../CustomTextInput";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOrganizationCustomTermsConditions, TermsAndConditionsLangageCode } from "@atman/business";
import { t } from "@lingui/macro";
import { useDebounce } from "@atman/core";

export interface ICollapsibleInputTextareaProps {
    languageCode: TermsAndConditionsLangageCode | string;
    setCustomConsentChange: Dispatch<SetStateAction<IOrganizationCustomTermsConditions | undefined>>;
    titleValue: string;
    textAreaValue: string;
}

export const CollapsibleInputTextarea: React.FC<ICollapsibleInputTextareaProps> = (
    props: ICollapsibleInputTextareaProps,
) => {
    const { languageCode, titleValue, textAreaValue, setCustomConsentChange } = props;

    const [titleContent, setTitleContent] = useState(titleValue || "");
    const titleContentDebounced = useDebounce<string>(titleContent, 500);

    const [descriptionContent, setDescriptionContent] = useState(textAreaValue || "");
    const descriptionContentDebounced = useDebounce<string>(descriptionContent, 500);

    const isfilled = titleContent.length > 0 && descriptionContent.length > 0;

    useEffect(() => {
        if (titleValue || titleValue === "") {
            setTitleContent(titleValue);
        }

        if (textAreaValue || textAreaValue === "") {
            setDescriptionContent(textAreaValue);
        }
    }, [textAreaValue, titleValue]);

    useEffect(() => {
        setCustomConsentChange((prevState) => {
            if (prevState) {
                return {
                    ...prevState,
                    customTermsConditions: {
                        title: prevState.customTermsConditions.title.set(languageCode, titleContent),
                        description: prevState.customTermsConditions.description.set(languageCode, descriptionContent),
                    },
                };
            }
        });
    }, [titleContentDebounced, descriptionContentDebounced]);

    const handleTitleInput = (e) => {
        setTitleContent(e.target.value);
    };

    const handleDescriptionTextarea = (e) => {
        setDescriptionContent(e.target.value);
    };

    return (
        <AtCollapsibleCard
            hideChevron
            className={`CollapsibleInputTextarea ${languageCode}-custom-consent`}
            title={t({ id: `global.languages.${languageCode}` })}
            headerPadding={[18, 4, 18, 4]}
            customRightHeaderContent={
                <div className="right-header-icons">
                    <AtIcon size="sm" icon={["fas", "badge-check"]} color={isfilled ? "green" : "grey"} />
                    <FontAwesomeIcon icon={["far", "chevron-down"]} />
                </div>
            }
            isInCollapsibleInputTextArea
        >
            <AtInput
                fieldName={`customConsent-${languageCode}-title`}
                value={titleContent}
                onChange={handleTitleInput}
                label={t({ id: "global.title" })}
                hideLabel
            />

            <CustomTextInput
                className="note-text-input"
                fieldName={`customConsent-${languageCode}-description`}
                label={t({ id: "global.description" })}
                value={descriptionContent}
                onChange={handleDescriptionTextarea}
                type={"textarea"}
                rows={3}
            />
        </AtCollapsibleCard>
    );
};
