import "./index.less";
import { AtBadgeStyle, AtIcon, DefaultComponentProps, DefaultSizes, XS } from "@atman/design-system";
import { CompetencyDevelopmentProcessStatus } from "@atman/business";
import { getDevProcessStatusColor } from "./TeamDevelopmentProcessLogic";
import { observer } from "mobx-react";
import { t } from "@lingui/macro";
import { useCompetencyIcon } from "../../hooks/useCompetencyHooks";
import React, { useMemo } from "react";
import cn from "classnames";
import moment from "moment";

export interface IProcessStatusProps extends DefaultComponentProps {
    status: CompetencyDevelopmentProcessStatus;
    dateStarted?: string;
    dateCompleted?: string;
    badgeSize?: XS | DefaultSizes;
    badgeStyle?: AtBadgeStyle;
    showDates?: boolean;
}

export const ProcessStatus: React.FC<IProcessStatusProps> = observer((props) => {
    const { status, dateStarted, dateCompleted, className, showDates = true } = props;

    const finalStatus =
        status !== undefined
            ? CompetencyDevelopmentProcessStatus[status]
            : CompetencyDevelopmentProcessStatus[CompetencyDevelopmentProcessStatus.NotStarted];
    const isStarted = finalStatus !== CompetencyDevelopmentProcessStatus[CompetencyDevelopmentProcessStatus.NotStarted];

    const icon = useCompetencyIcon(status);
    const badgeColor = getDevProcessStatusColor(status, true);

    const processStatusLabel = t({ id: `global.compDevProcessStatus.${finalStatus.toCamel()}` });
    const processStatusDateLabel = t({ id: `global.compDevProcessStatus.${finalStatus.toCamel()}On` });

    const date = useMemo(() => {
        if (status === CompetencyDevelopmentProcessStatus.Completed) {
            return dateCompleted;
        } else if (status === CompetencyDevelopmentProcessStatus.Started) {
            return dateStarted;
        }

        return undefined;
    }, [dateCompleted, dateStarted, status]);

    const displayDate = showDates && date !== undefined && isStarted ? true : false;

    return (
        <div className={cn("ProcessStatus", className)}>
            <div className="main">
                <AtIcon icon={icon} color={badgeColor} />
                <span className="text">{processStatusLabel}</span>
            </div>
            {displayDate && (
                <div className="second">{`${processStatusDateLabel} ${moment(date).format("YYYY-MM-DD")}`}</div>
            )}
        </div>
    );
});
