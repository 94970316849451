import { ColorProperty } from "csstype";
import { Colors } from "../../styles";
import { CompetencyDevelopmentProcessStatus, CompetencyDevelopmentProcessStep } from "@atman/business";

/**
 * Get correct comp dev process color according to its status and step (if started)
 * @param status Status of the process
 * @param step Step of the process if started
 */
export function getDevProcessStatusColor(
    status: CompetencyDevelopmentProcessStatus | undefined,
    baseColor?: boolean,
): ColorProperty {
    if (status === undefined) {
        return Colors.greyShades.shade9;
    }

    if (baseColor) {
        if (status === CompetencyDevelopmentProcessStatus.Cancelled) {
            return Colors.redBase;
        }
        if (status === CompetencyDevelopmentProcessStatus.Started) {
            return Colors.yellowBase;
        }
        if (status === CompetencyDevelopmentProcessStatus.Completed) {
            return Colors.greenBase;
        }

        return Colors.greyShades.shade9;
    } else {
        return Colors.DEVELOPMENT_PROCESS_STATUS_COLORS_MAPPING.get(status) || Colors.greyShades.shade9;
    }
}

// Take both status and step and make a sortable number with it.
export function getDevProcessStatusAndStepValue(
    status?: CompetencyDevelopmentProcessStatus,
    step?: CompetencyDevelopmentProcessStep,
): number {
    if (status === undefined) return -1;
    if (status === CompetencyDevelopmentProcessStatus.Cancelled) return -0.5;

    let val = status as number;
    if (step !== undefined) val = val + step / 10; // The step here became the decimal.

    return val;
}
