import { AssessmentType, CompetencyDevelopmentProcessStatus, LikertValue } from "@atman/business";
import { BaseColors, StatusColors, tint } from "@atman/design-system";
import { ColorProperty } from "csstype";

export class Colors {
    public static readonly primaryButtonColor = "#00B5DD";
    public static readonly primaryButtonHoverColor = "#6c757d";
    public static readonly secondaryButtonHoverColor = "#5a6268";
    public static readonly contrastColor = "#EFEFEF";
    public static readonly white = "#FFFFFF";
    public static readonly almostWhite = "#FAFAFA";
    public static readonly atmanOrange = "#F08752";
    public static readonly atmanOrangeFaded = tint(Colors.atmanOrange, 70);
    public static readonly atmanGradientYellow = "#FAC13A";
    public static readonly borderColor = "#DBDBDB";

    public static readonly preferenceAssessmentColor = "#973892";
    public static readonly cognitiveAssessmentColor = "#00B5DE";
    public static readonly personalityAssessmentColor = "#FCC23C";
    public static readonly learningAssessmentColor = "#AFAFAF";
    public static readonly tripleBottomLineAssessmentColor = "#438411";
    public static readonly competenciesAssessmentColor = "#37A07E";

    // @region: App Colors V2
    public static readonly gradient = "linear-gradient(290deg, #F08752, #F18C50, #F39B4A, #F8B340, #FAC13A)";
    public static readonly primaryColor = "#30A1E2";
    public static readonly successColor = "#48D960";
    public static readonly warningColor = "#DBCB42";
    public static readonly errorColor = "#EB5757";
    public static readonly actionColor = "#1B59EA";
    // @endregion: App Colors V2

    public static get inputBorderColor() {
        return Colors.greyShades.shade7;
    }
    public static get inputBorderColor__Hover() {
        return Colors.greyShades.shade5;
    }
    public static get inputBorderColor__Active() {
        return Colors.primaryBase;
    }
    public static get inputBoxShadow() {
        return `0 0 0 2px ${Colors.primaryShades.shade6}`;
    }

    // @region: App Colors V3
    public static readonly greyBase = "#303030";
    public static readonly greyBg = tint(Colors.greyBase, 94);
    public static readonly greyShades = Colors.generateShades(Colors.greyBase);

    public static readonly primaryBase = "#1B59EA";
    public static readonly primaryBg = tint(Colors.primaryBase, 94);
    public static readonly primaryShades = Colors.generateShades(Colors.primaryBase);

    public static readonly greenBase = "#48B084";
    public static readonly greenBg = tint(Colors.greenBase, 94);
    public static readonly greenShades = Colors.generateShades(Colors.greenBase);

    public static readonly yellowBase = "#FAC13A";
    public static readonly yellowBg = tint(Colors.yellowBase, 94);
    public static readonly yellowShades = Colors.generateShades(Colors.yellowBase);

    public static readonly redBase = "#EB5757";
    public static readonly redBg = tint(Colors.redBase, 94);
    public static readonly redShades = Colors.generateShades(Colors.redBase);

    public static readonly purpleBase = "#8A3964";
    public static readonly purpleBg = tint(Colors.purpleBase, 94);
    public static readonly purpleShades = Colors.generateShades(Colors.purpleBase);

    public static readonly blueGreenGradient1 = "#123680";
    public static readonly blueGreenGradient2 = "#194580";
    public static readonly blueGreenGradient3 = "#1F5480";
    public static readonly blueGreenGradient4 = "#266481";
    public static readonly blueGreenGradient5 = "#2B7382";
    public static readonly blueGreenGradient6 = "#328382";
    public static readonly blueGreenGradient7 = "#399283";
    public static readonly blueGreenGradient8 = "#3FA183";
    public static readonly blueGreenGradient9 = "#48B084";

    public static readonly reportGreen = "#429777";

    // @endregion : App Colors V3

    public static readonly likertScaleColors: Map<LikertValue, ColorProperty> = new Map<LikertValue, ColorProperty>([
        [LikertValue.StronglyDisagree, Colors.redBase],
        [LikertValue.Disagree, Colors.redShades.shade4],
        [LikertValue.NeitherAgreeNorDisagree, Colors.yellowBase],
        [LikertValue.Agree, Colors.greenShades.shade4],
        [LikertValue.StronglyAgree, Colors.greenBase],
    ]);

    public static readonly LIKERT_INTERPRETATION_RANGE_COLOR_MAPPING: Map<LikertValue, LikertColorGroup> = new Map<
        LikertValue,
        LikertColorGroup
    >([
        [
            LikertValue.StronglyDisagree,
            {
                textColor: Colors.white,
                backgroundColor: Colors.redShades.shade1,
                borderColor: Colors.redBase,
            },
        ],
        [
            LikertValue.Disagree,
            {
                textColor: Colors.white,
                backgroundColor: Colors.redShades.shade3,
                borderColor: Colors.redBase,
            },
        ],
        [
            LikertValue.NeitherAgreeNorDisagree,
            {
                textColor: Colors.white,
                backgroundColor: Colors.yellowShades.shade3,
                borderColor: Colors.yellowBase,
            },
        ],
        [
            LikertValue.Agree,
            {
                textColor: Colors.white,
                backgroundColor: Colors.greenShades.shade3,
                borderColor: Colors.greenBase,
            },
        ],
        [
            LikertValue.StronglyAgree,
            {
                textColor: Colors.white,
                backgroundColor: Colors.greenShades.shade1,
                borderColor: Colors.greenBase,
            },
        ],
    ]);

    public static readonly STANINE_SCORE_TO_GRADIENT_MAPPING: Map<number, ColorProperty> = new Map<
        number,
        ColorProperty
    >([
        [1, Colors.blueGreenGradient1],
        [2, Colors.blueGreenGradient2],
        [3, Colors.blueGreenGradient3],
        [4, Colors.blueGreenGradient4],
        [5, Colors.blueGreenGradient5],
        [6, Colors.blueGreenGradient6],
        [7, Colors.blueGreenGradient7],
        [8, Colors.blueGreenGradient8],
        [9, Colors.blueGreenGradient9],
    ]);

    public static getLikertScoreColorGroup(likertValue: LikertValue | undefined): LikertColorGroup | undefined {
        if (likertValue === undefined) {
            return undefined;
        }

        return Colors.LIKERT_INTERPRETATION_RANGE_COLOR_MAPPING.get(likertValue);
    }

    public static getAssessmentColor(assessmentType: AssessmentType, isCompetencyGroup: boolean = false) {
        if (isCompetencyGroup) {
            return Colors.competenciesAssessmentColor;
        }

        switch (assessmentType) {
            case AssessmentType.ProCognitiveTest:
                return Colors.cognitiveAssessmentColor;
            case AssessmentType.ProPersonalityTest:
                return Colors.personalityAssessmentColor;
            case AssessmentType.ProPreferencesTest:
                return Colors.preferenceAssessmentColor;
            case AssessmentType.ProTripleBottomLineTest:
                return Colors.tripleBottomLineAssessmentColor;
            case AssessmentType.ProLearningTest:
                return Colors.learningAssessmentColor;
            default:
                return Colors.atmanOrange;
        }
    }

    public static readonly DEVELOPMENT_PROCESS_STATUS_COLORS_MAPPING: Map<
        CompetencyDevelopmentProcessStatus,
        ColorProperty
    > = new Map<CompetencyDevelopmentProcessStatus, ColorProperty>([
        [CompetencyDevelopmentProcessStatus.Cancelled, Colors.redShades.shade5],
        [CompetencyDevelopmentProcessStatus.Started, Colors.yellowShades.shade5],
        [CompetencyDevelopmentProcessStatus.Completed, Colors.greenShades.shade5],
        [CompetencyDevelopmentProcessStatus.NotStarted, Colors.greyShades.shade9],
    ]);

    // public static readonly DEVELOPMENT_PROCESS_STEP_COLORS_MAPPING: Map<
    //     CompetencyDevelopmentProcessStep,
    //     ColorProperty
    // > = new Map<CompetencyDevelopmentProcessStep, ColorProperty>([
    //     [CompetencyDevelopmentProcessStep.Started, Colors.yellowShades.shade2],
    //     [CompetencyDevelopmentProcessStep.Completed, Colors.primaryBase],
    // ]);

    public static generateShades(baseColor: ColorProperty): IColorShades {
        return {
            shade1: tint(baseColor, 10),
            shade2: tint(baseColor, 20),
            shade3: tint(baseColor, 30),
            shade4: tint(baseColor, 40),
            shade5: tint(baseColor, 50),
            shade6: tint(baseColor, 60),
            shade7: tint(baseColor, 70),
            shade8: tint(baseColor, 80),
            shade9: tint(baseColor, 87),
        };
    }

    /**
     * @deprecated use `greyShades.shade2` instead
     */
    public static readonly veryDarkGrey = "#4B4B4B";
    /**
     * @deprecated
     */
    public static readonly darkGrey = "#707070";
    /**
     * @deprecated
     */
    public static readonly lightGrey = "#818181";
    /**
     * @deprecated
     */
    public static readonly veryLightGrey = "#C7C7C7";
    /**
     * @deprecated use `greenBase` instead
     */
    public static readonly appGreenColor = "#3FDB4A";
    /**
     * @deprecated use `yellowBase` instead
     */
    public static readonly appYellowColor = "#DBCB42";
    /**
     * @deprecated use `redBase` instead
     */
    public static readonly appRedColor = "#DF5B21";
    /**
     * @deprecated use `greenBase` instead
     */
    public static readonly matchGreenColor = "#0BBC95";
    /**
     * @deprecated use `yellowBase` instead
     */
    public static readonly matchYellowColor = "#FFDA73";
    /**
     * @deprecated use `redBase` instead
     */
    public static readonly matchRedColor = "#FF6961";
    /**
     * @deprecated use `greyShades` instead
     */
    public static readonly greyColors = {
        lighter9: "#EDEDED",
        lighter8: "#DBDBDB",
        lighter7: "#C9C9C9",
        lighter6: "#B7B7B7",
        lighter5: "#A5A5A5",
        lighter4: "#939393",
        lighter3: "#818181",
        lighter2: "#6E6E6E",
        lighter1: "#5D5D5D",
        main: "#4B4B4B",
        darker1: "#434343",
        darker2: "#3C3C3C",
        darker3: "#343434",
        darker4: "#2D2D2D",
        darker5: "#252525",
        darker6: "#1E1E1E",
        darker7: "#161616",
        darker8: "#0F0F0F",
        darker9: "#070707",
    };
}

export interface IColorShades {
    shade1: ColorProperty;
    shade2: ColorProperty;
    shade3: ColorProperty;
    shade4: ColorProperty;
    shade5: ColorProperty;
    shade6: ColorProperty;
    shade7: ColorProperty;
    shade8: ColorProperty;
    shade9: ColorProperty;
}

export function getColorDict(color: BaseColors | StatusColors | "blueGreenGradient" | "likert") {
    switch (color) {
        case "primary":
            return {
                0: Colors.primaryBase,
                100: Colors.primaryShades.shade1,
                200: Colors.primaryShades.shade2,
                300: Colors.primaryShades.shade3,
                400: Colors.primaryShades.shade4,
                500: Colors.primaryShades.shade5,
                600: Colors.primaryShades.shade6,
                700: Colors.primaryShades.shade7,
                800: Colors.primaryShades.shade8,
                900: Colors.primaryShades.shade9,
                bg: Colors.primaryBg,
            };
        case "secondary":
            return {
                0: Colors.greyBase,
                100: Colors.greyShades.shade1,
                200: Colors.greyShades.shade2,
                300: Colors.greyShades.shade3,
                400: Colors.greyShades.shade4,
                500: Colors.greyShades.shade5,
                600: Colors.greyShades.shade6,
                700: Colors.greyShades.shade7,
                800: Colors.greyShades.shade8,
                900: Colors.greyShades.shade9,
                bg: Colors.greyBg,
            };
        case "success":
            return {
                0: Colors.greenBase,
                100: Colors.greenShades.shade1,
                200: Colors.greenShades.shade2,
                300: Colors.greenShades.shade3,
                400: Colors.greenShades.shade4,
                500: Colors.greenShades.shade5,
                600: Colors.greenShades.shade6,
                700: Colors.greenShades.shade7,
                800: Colors.greenShades.shade8,
                900: Colors.greenShades.shade9,
                bg: Colors.greenBg,
            };
        case "warning":
            return {
                0: Colors.yellowBase,
                100: Colors.yellowShades.shade1,
                200: Colors.yellowShades.shade2,
                300: Colors.yellowShades.shade3,
                400: Colors.yellowShades.shade4,
                500: Colors.yellowShades.shade5,
                600: Colors.yellowShades.shade6,
                700: Colors.yellowShades.shade7,
                800: Colors.yellowShades.shade8,
                900: Colors.yellowShades.shade9,
                bg: Colors.yellowBg,
            };
        case "danger":
            return {
                0: Colors.redBase,
                100: Colors.redShades.shade1,
                200: Colors.redShades.shade2,
                300: Colors.redShades.shade3,
                400: Colors.redShades.shade4,
                500: Colors.redShades.shade5,
                600: Colors.redShades.shade6,
                700: Colors.redShades.shade7,
                800: Colors.redShades.shade8,
                900: Colors.redShades.shade9,
                bg: Colors.redBg,
            };
        case "blueGreenGradient":
            return {
                100: Colors.blueGreenGradient1,
                200: Colors.blueGreenGradient2,
                300: Colors.blueGreenGradient3,
                400: Colors.blueGreenGradient4,
                500: Colors.blueGreenGradient5,
                600: Colors.blueGreenGradient6,
                700: Colors.blueGreenGradient7,
                800: Colors.blueGreenGradient8,
                900: Colors.blueGreenGradient9,
            };
        case "likert":
            return {
                "1.0 to 1.5": Colors.getLikertScoreColorGroup(LikertValue.StronglyDisagree)?.borderColor,
                "1.5 to 2.5": Colors.getLikertScoreColorGroup(LikertValue.Disagree)?.borderColor,
                "2.5 to 3.5": Colors.getLikertScoreColorGroup(LikertValue.NeitherAgreeNorDisagree)?.borderColor,
                "3.5 to 4.5": Colors.getLikertScoreColorGroup(LikertValue.Agree)?.borderColor,
                "4.5 to 5.0": Colors.getLikertScoreColorGroup(LikertValue.StronglyAgree)?.borderColor,
            };
    }
}

export type LikertColorGroup = {
    textColor: ColorProperty;
    backgroundColor: ColorProperty;
    borderColor: ColorProperty;
};
